import "../src/assets/css/bootstrap.min.css";
import "../src/assets/css/all.min.css";
import "../src/assets/css/style.css";
import "../src/assets/css/responsive.css";

import HomeBanner from "./components/BannerSlider";
import Download from "./components/Download";
import Geography from "./components/Geography";
import GeograpicSlider from "./components/GeograpicSlider";
import Header from "./components/Header";
import LatestRelease from "./components/LatestRelease";
import Superheroes from "./components/Superheroes";
import TopMovie from "./components/TopMovies";
import "react-multi-carousel/lib/styles.css";
import VideoSec from "./components/VideoSliderSec";
import Brands from "./components/Brands";
import Donate from "./components/Donate";
import Footer from "./components/Footer";
import AppRouter from "./router/AppRouter";
import { useEffect, useState } from "react";
import * as API from "./API/Index";
function App() {
  const [location, setLocation] = useState({ latitude: null, longitude: null });
  const [error, setError] = useState(null);
  console.log("location", location);

  const getLocation = async () => {
    try {
      const response = await API.getIp();
      console.log("responseIIPPP", response);
      const reqObj = {
        ip: response.data.ip,
      };
      const gresponse = await API.sentIp(reqObj);
      console.log("gresponse", gresponse);
    } catch (error) {}
  };

  useEffect(() => {
    getLocation();
  }, []);

  return (
    <>
      {/* <div className="loader-container">
        <div className="loader"></div>
      </div> */}
      <AppRouter />
    </>
  );
}

export default App;
