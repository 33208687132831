import React from "react";

const Plans = () => {
  return (
    <>
      <section class="movie_list innerpage pricingPaid">
        <div class="container-fluid">
          <div class="row justify-content-center">
            <div class="col-md-11 text-center">
              <h2 class="text-capitalize">Choose your business plan</h2>
              <p>
                Explore advanced AI tools to enhance your marketing strategy.
              </p>
              <div class="d-flex justify-content-center align-items-center gap-3 mb-5">
                <span>Bill Monthly</span>
                <div class="form-check form-switch">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="billingToggle"
                  />
                </div>
                <span>Bill Annually (10% off)</span>
              </div>

              <div class="row row-cols-1 row-cols-md-2 row-cols-lg-4 g-4">
                <div class="col">
                  <div class="pricing-card">
                    <div class="text-center mb-4">
                      <h3 class="fw-bold">BASIC</h3>
                      <p class="text-muted">
                        Create targeted campaigns with essential tools.
                      </p>
                      <div class="price mb-4">
                        $19<span class="period">/mo</span>
                      </div>
                      <button class="get-plan-btn mb-4">Get Plan</button>
                    </div>
                    <ul class="feature-list">
                      <li>50 GB of storage</li>
                      <li>Earn revenue (70/30 split)</li>
                      <li>Access to 1 IMBOXO festival</li>
                      <li>Social media sharing tools</li>
                      <li>Bio and film display</li>
                    </ul>
                  </div>
                </div>

                <div class="col">
                  <div class="pricing-card">
                    <div class="text-center mb-4">
                      <h3 class="fw-bold">PLUS</h3>
                      <p class="text-muted">
                        Expand your reach with enhanced features.
                      </p>
                      <div class="price mb-4">
                        $45<span class="period">/mo</span>
                      </div>
                      <button class="get-plan-btn mb-4">Get Plan</button>
                    </div>
                    <ul class="feature-list">
                      <li>200 GB of storage</li>
                      <li>Earn revenue (80/20 split)</li>
                      <li>Priority entry in 2 festivals</li>
                      <li>Advanced analytics</li>
                      <li>Custom profile with trailers</li>
                      <li>Networking with other filmmakers</li>
                    </ul>
                  </div>
                </div>

                <div class="col">
                  <div class="pricing-card">
                    <div class="text-center mb-4">
                      <h3 class="fw-bold">PRO</h3>
                      <p class="text-muted">
                        Create targeted campaigns with essential tools.
                      </p>
                      <div class="price mb-4">
                        $99<span class="period">/mo</span>
                      </div>
                      <button class="get-plan-btn mb-4">Get Plan</button>
                    </div>
                    <ul class="feature-list">
                      <li>1 TB of storage</li>
                      <li>In-depth audience insights</li>
                      <li>Earn revenue (90/10 split)</li>
                      <li>Priority entry in 3 festivals with prime slots</li>
                      <li>Customizable virtual screening rooms</li>
                      <li>Interactive Q&A sessions</li>
                    </ul>
                  </div>
                </div>

                <div class="col">
                  <div class="pricing-card">
                    <div class="text-center mb-4">
                      <h3 class="fw-bold">ENTERPRISE</h3>
                      <p class="text-muted">
                        Unlock limitless potential with custom solutions.
                      </p>
                      <div class="infinity-symbol mb-4">∞</div>
                      <button class="get-plan-btn mb-4">Get Plan</button>
                    </div>
                    <ul class="feature-list">
                      <li>Custom pricing</li>
                      <li>Custom film hosting</li>
                      <li>Priority festival entry with VIP exposure</li>
                      <li>Custom revenue split agreements</li>
                      <li>Detailed audience and engagement reports</li>
                      <li>Personalized support for large film libraries</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Plans;
