import React, { useEffect, useState } from "react";
import { BeatLoader } from "react-spinners";
import SideBar from "../UserDashboard/SideBar";
import ProfileSection from "./ProfileSection";
import Select from "react-select";
import * as API from "../../API/Index.js";
import { MESSAGE } from "../../schemas/Validation.js";
import { Check } from "@material-ui/icons";
import { validateForm } from "../../AppUtilities.js";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import VideoUpload from "./VideoUpload.js";
import Modal from "react-responsive-modal";
import { useLocation, useParams } from "react-router";
import DatePicker from "react-datepicker";
import { IMG } from "../../API/constant.js";
const initialValues = {
  movie_name: "",
  description: "",
  access: "",
  release_date: "",
  duration: "",
  cast: "",
  crew: "",
  upload_trailer: "",
  upload_movie: "",
  rating: "",
  thumbnail: "",
  poster: "",
  certificate: "",
  country: "",
};

const MovieUpload = ({ data, setIsLogin }) => {
  const [formData, setFormData] = useState(initialValues);
  const [genreData, setGenreData] = useState([]);
  const [languageData, setLanguageData] = useState([]);
  const [qualityData, setQualityData] = useState([]);
  const [castData, setCastData] = useState([]);
  const [crewData, setCrewData] = useState([]);
  const [certiData, setcertiData] = useState([]);
  const [isMove, setIsMove] = useState(1);
  const [posterImg, setPosterImg] = useState(null);
  const [imageSrc, setImageSrc] = useState("");
  const [albumImg, setAlbumImg] = useState(null);
  const [albSrc, setAlbSrc] = useState("");
  const [isLoder, setIsLoder] = useState(false);
  const [isLoderm, setIsLoderm] = useState(false);
  const [allMovieProps, setAllMovieProps] = useState([]);
  const [moveiInsId, setMoveiInsId] = useState("");
  const [allCart, setAllCart] = useState([]);
  const [open, setOpen] = useState(false);
  const [roleType, setRoleType] = useState("");
  const [allCountryData, setAllCountryData] = useState([]);
  const [castNcrewName, setCastNcrewName] = useState("");
  const [castcrewImg, setCastcrewImg] = useState("");
  const [roleValue, setRoleValue] = useState("");

  //console.log("allMovieProps", allMovieProps);
  // ? New Array List
  const [newArry, setNewArry] = useState("");
  const [startDate, setStartDate] = useState(new Date());
  const loaction = useLocation();

  const genrehandalerChanges = async (data) => {
    const transformedData = data.map((item) => item.value);
    setGenreData(transformedData);
  };

  const languhandalerChanges = async (data) => {
    const transformedData = data.map((item) => item.value);
    setLanguageData(transformedData);
  };

  const qualithandalerChanges = async (data) => {
    const transformedData = data.map((item) => item.value);
    setQualityData(transformedData);
  };

  const casthandalerChanges = async (data) => {
    const transformedData = data.map((item) => item.value);
    setCastData(transformedData);
  };

  const crewhandalerChanges = async (data) => {
    const transformedData = data.map((item) => item.value);
    setCrewData(transformedData);
  };

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? "#e9840c" : null,
      color: state.isFocused ? "#fff" : "#fff",
    }),
    control: (provided) => ({
      ...provided,
    }),
  };

  const allMovieData = async () => {
    const header = localStorage.getItem("_tokenCode");
    try {
      const response = await API.getMovieAllProps();
      console.log("response", response);
      const cresponse = await API.allCountry(header);
      const moviebyID = await API.creatorVideoGetbyId(
        loaction.state.id,
        header
      );
      console.log("moviebyID", moviebyID);
      setStartDate(moviebyID.data.data.release_date);
      setFormData(moviebyID.data.data);
      setAllCountryData(cresponse.data.data);
      setAllCart(response.data.data.certificate_list);
      if (response.data.success === 1) {
        let mainRes = response.data.data;
        console.log("mainRes", mainRes);
        const newData = {
          ...mainRes,
          cast_list: mainRes.cast_list.map(({ value, label }) => ({
            value,
            label,
          })),
          crew_list: mainRes.crew_list.map(({ value, label }) => ({
            value,
            label,
          })),
        };
        setAllMovieProps(newData);
      }
    } catch (error) {}
  };

  const handalerChanges = async (e) => {
    const { name, value } = e.target;
    setIsLoder(false);
    setFormData({ ...formData, [name]: value });
  };

  const handalerChangespost = (e) => {
    const images = e.target.files[0];
    setPosterImg(images);
    setIsLoder(false);
    if (images) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImageSrc(reader.result);
      };
      reader.readAsDataURL(images);
    }
  };

  const handalerChangesalbum = (e) => {
    const image = e.target.files[0];
    setIsLoder(false);
    setAlbSrc(image.name);
    setAlbumImg(image);
  };

  console.log("qualityData", qualityData);
  const uploadVideo = async () => {
    const header = localStorage.getItem("_tokenCode");
    setIsLoder(true);
    try {
      const formDataObject = {
        step: "one",
        movie_name: formData.movie_name,
        description: formData.description,
        access: formData.access,
        language: languageData,
        genre: genreData,
        quality: qualityData,
        release_date: startDate,
        cast: castData,
        crew: crewData,
        certificate: formData.certificate,
        thumbnail: albumImg,
        poster: posterImg,
        user_id: localStorage.getItem("_userId"),
        country: formData.country,
      };
      console.log("formDataObject", formDataObject);

      const validationErrors = validateForm(formDataObject);
      if (validationErrors.length > 0) {
        validationErrors.length > 0 &&
          validationErrors.map((error, index) => MESSAGE(error));
      } else {
        const response = await API.creatorVideoAdd(formDataObject, header);
        console.log("response", response);
        if (response.data.success === 1) {
          setMoveiInsId(response.data.insertId);
          setIsMove(2);
          setIsLoder(false);
          MESSAGE(response.data.message, 1);
        } else {
          setIsLoder(false);
        }
      }
    } catch (error) {}
  };

  const onOpenModal = (type) => {
    setOpen(true);
    setRoleType(type);
  };
  const onCloseModal = () => setOpen(false);

  const addMovieCastCrew = async () => {
    setIsLoderm(true);
    const header = localStorage.getItem("_tokenCode");
    try {
      const reqObj = {
        name: castNcrewName,
        file: castcrewImg,
        role: roleValue,
      };

      if (roleType === "cast") {
        delete reqObj.role;

        const response = await API.addMovieCastCrew(reqObj, header);
        console.log("response", response);
        setNewArry(response.data.data);

        if (response.data.success === 1) {
          setNewArry(response.data.data);
          setCastcrewImg("");
          allMovieData();
          setIsLoderm(false);
          MESSAGE(response.data.message, 1);
          setOpen(false);
        } else {
          MESSAGE(response.data.message);
          setIsLoderm(false);
        }
      } else {
        const response = await API.addMovieCrew(reqObj, header);
        console.log("response", response);
        if (response.data.success === 1) {
          setCastcrewImg("");
          allMovieData();
          setIsLoderm(false);
          MESSAGE(response.data.message, 1);
          setOpen(false);
        } else {
          MESSAGE(response.data.message);
          setIsLoderm(false);
        }
      }
    } catch (error) {}
  };

  // const dValue = {
  //   value: 18,
  //   label: "hello",
  // };

  // setNewArry(dValue);
  const defaultValue = newArry;

  useEffect(() => {
    allMovieData();
  }, [newArry]);
  return (
    <>
      <ProfileSection formData={data} />

      <div class="container-fluid">
        <div class="row justify-content-center">
          <div class="jj">
            <div class="tab_main editProfile">
              <div class="row justify-content-center g-0">
                <div class="col-sm-12 col-md-12 row">
                  <div className="col-md-3">
                    <SideBar formData={data} setIsLogin={setIsLogin} />
                  </div>
                  <div className="col-md-9">
                    <div className="tab-content">
                      {isMove === 1 ? (
                        <div class="row row--form">
                          <div class="col-12 col-md-5 form__cover">
                            <div class="row row--form">
                              <div class="col-12 col-sm-6 col-md-12">
                                <div class="form__img">
                                  <label for="form__img-upload">
                                    Upload Poster (270 x 400)
                                  </label>
                                  <form encType="multipart/form-data">
                                    <input
                                      id="form__img-upload"
                                      name="poster"
                                      //value={formData.poster}
                                      onChange={handalerChangespost}
                                      type="file"
                                      accept=".png, .jpg, .jpeg"
                                    />
                                  </form>

                                  <img
                                    id="form__img"
                                    src={
                                      imageSrc
                                        ? imageSrc
                                        : IMG + formData.poster
                                    }
                                    alt=""
                                  />
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="col-12 col-md-7 form__content">
                            <div class="row row--form">
                              <div class="col-12">
                                <input
                                  type="text"
                                  class="form__input"
                                  placeholder="Title"
                                  name="movie_name"
                                  value={formData.movie_name}
                                  onChange={handalerChanges}
                                />
                              </div>
                              <div class="col-12">
                                <textarea
                                  id="text"
                                  name="description"
                                  class="form__textarea"
                                  value={formData.description}
                                  placeholder="Description"
                                  onChange={handalerChanges}
                                ></textarea>
                              </div>
                              <div class="col-12 col-sm-6 col-lg-6">
                                <DatePicker
                                  className="form__input"
                                  selected={startDate}
                                  onChange={(date) => setStartDate(date)}
                                />
                              </div>

                              <div class="col-12 col-sm-6 col-lg-6">
                                <select
                                  onChange={handalerChanges}
                                  name="access"
                                  value={formData.access}
                                  className="form__input select_drop"
                                >
                                  <option>--- Access ---</option>
                                  <option value="Free"> Free </option>
                                  <option value="Premium"> Premium</option>
                                </select>
                              </div>
                              <div class="col-12 col-sm-6 col-lg-12">
                                <div class="form__gallery">
                                  <label
                                    id="gallery1"
                                    for="form__gallery-upload"
                                    className="pe-2"
                                  >
                                    <div className="d-flex justify-content-between w-100 cehckthum">
                                      <span>
                                        {albSrc ? albSrc : "Upload Thumbnail"}
                                      </span>
                                      {albSrc ? <Check color="#000" /> : ""}
                                    </div>
                                  </label>
                                  <form encType="multipart/form-data">
                                    <input
                                      data-name="#gallery1"
                                      id="form__gallery-upload"
                                      class="form__gallery-upload"
                                      type="file"
                                      accept=".png, .jpg, .jpeg"
                                      multiple=""
                                      //value={formData.thumbnail}
                                      onChange={handalerChangesalbum}
                                      name="thumbnail"
                                    />
                                  </form>
                                </div>
                              </div>

                              <div class="col-12 col-sm-6 col-lg-12">
                                <Select
                                  styles={customStyles}
                                  isMulti
                                  options={allMovieProps.quality_list}
                                  className="basic-multi-select"
                                  classNamePrefix="select"
                                  placeholder="Choose quality"
                                  name="quality_list"
                                  onChange={qualithandalerChanges}
                                />
                              </div>
                            </div>
                          </div>
                          <div class="col-12 col-sm-6 col-lg-12">
                            <Select
                              styles={customStyles}
                              isMulti
                              name="genres_list"
                              options={allMovieProps.genres_list}
                              className="basic-multi-select"
                              classNamePrefix="select"
                              placeholder="Choose genres"
                              onChange={genrehandalerChanges}
                            />
                          </div>
                          <div class="col-12">
                            <Select
                              styles={customStyles}
                              isMulti
                              name="language_list"
                              options={allMovieProps.language_list}
                              className="basic-multi-select"
                              classNamePrefix="select"
                              placeholder="Choose language"
                              onChange={languhandalerChanges}
                            />
                          </div>

                          <div class="col-12 col-sm-6 col-lg-10">
                            <Select
                              defaultValue={defaultValue}
                              styles={customStyles}
                              isMulti
                              name="cast_list"
                              options={allMovieProps.cast_list}
                              className="basic-multi-select"
                              classNamePrefix="select"
                              placeholder="Choose cast"
                              onChange={casthandalerChanges}
                            />
                          </div>
                          <div class="col-12 col-sm-6 col-lg-2">
                            <button
                              className="btn btn-secondary2"
                              onClick={() => onOpenModal("cast")}
                            >
                              Add Cast
                            </button>
                          </div>
                          <div class="col-12 col-sm-6 col-lg-10">
                            <Select
                              isMulti
                              styles={customStyles}
                              name="crew_list"
                              options={allMovieProps.crew_list}
                              className="basic-multi-select"
                              classNamePrefix="select"
                              placeholder="Choose crew"
                              onChange={crewhandalerChanges}
                            />
                          </div>
                          <div class="col-12 col-sm-6 col-lg-2">
                            <button
                              className="btn btn-secondary2"
                              onClick={() => onOpenModal("crew")}
                            >
                              Add Crew
                            </button>
                          </div>
                          <div class="col-12 col-sm-6 col-lg-12">
                            <select
                              onChange={handalerChanges}
                              name="certificate"
                              value={formData.certificate}
                              className="form__input select_drop"
                            >
                              <option>--- Certificate ---</option>
                              {allCart.map((item, index) => (
                                <option key={index} value={item.value}>
                                  {item.label}
                                </option>
                              ))}
                            </select>
                          </div>

                          <div class="col-12 col-sm-6 col-lg-12">
                            <select
                              onChange={handalerChanges}
                              name="country"
                              value={formData.country}
                              className="form__input select_drop"
                            >
                              <option>--- Country of Origin ---</option>
                              {allCountryData.map((item, index) => (
                                <option key={index} value={item.id}>
                                  {item.name}
                                </option>
                              ))}
                            </select>
                          </div>

                          <div class="col-12">
                            <div class="row row--form">
                              <div class="col-12">
                                {isLoder ? (
                                  <button
                                    type="button"
                                    class="btn btn btn-secondary2 mt-3"
                                  >
                                    <BeatLoader color="#fff" />
                                  </button>
                                ) : (
                                  <button
                                    type="button"
                                    onClick={uploadVideo}
                                    class="btn btn btn-secondary2 mt-3"
                                  >
                                    Next
                                  </button>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <VideoUpload
                          moveiInsId={moveiInsId}
                          setIsMove={setIsMove}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal open={open} onClose={onCloseModal} center>
        <div className="modalBody">
          <h4 className="mb-4 text-capitalize text-dark">Add {roleType}</h4>
          <input
            className="form-control mb-3"
            type="text"
            onChange={(v) => setCastNcrewName(v.target.value)}
            placeholder="Name"
          />
          <label className="uplaodimage" for="cuser">
            <span className="btn btn btn-secondary2">
              <i class="bi bi-file-earmark-arrow-up"></i> Image
            </span>

            <strong className="d-inline ms-1">{castcrewImg.name}</strong>

            <input
              onChange={(v) => setCastcrewImg(v.target.files[0])}
              id="cuser"
              hidden
              className="form-control"
              type="file"
            />
          </label>
          {roleType === "cast" ? (
            ""
          ) : (
            <select
              className="form-control mb-3"
              onChange={(v) => setRoleValue(v.target.value)}
            >
              <option>--- Select Role ---</option>
              {allMovieProps.length === 0 || allMovieProps === undefined
                ? ""
                : allMovieProps.role_list.map((item, index) => (
                    <option key={index} value={item.value}>
                      {item.label}
                    </option>
                  ))}
            </select>
          )}

          {isLoderm ? (
            <button type="button" class="btn btn btn-secondary2 mt-3">
              <BeatLoader color="#fff" />
            </button>
          ) : (
            <button
              className="btn btn btn-secondary2 mt-3"
              onClick={addMovieCastCrew}
            >
              Add
            </button>
          )}
        </div>
      </Modal>
    </>
  );
};

export default MovieUpload;
