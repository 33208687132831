import React from "react";
import { logos } from "../AppUtilities";
import { appOne } from "../AppUtilities";
import { appTwo } from "../AppUtilities";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <>
      <footer>
        <div class="container">
          <div class="row">
            <div class="col-xl-2 col-md-4 col-sm-12">
              <span class="logo">
                <Link to="/">
                  <img src={logos} alt="" class="img-fluid" />
                </Link>
              </span>
              <h6 class=" mt-4">Newsletter</h6>
              <div class="relative">
                <input
                  type="text"
                  class="form-control mt-2 mb-2"
                  id=""
                  placeholder="Email Address"
                />
                <button class="btn btn-sm btn-secondary2">
                  <i class="fa-solid fa-paper-plane"></i>
                </button>
              </div>

              <div class="d-flex justify-content-start">
                <span class="footer_social">
                  <Link to="#">
                    <i class="fa-brands fa-facebook-f"></i>
                  </Link>
                </span>
                <span class="footer_social">
                  <Link to="#">
                    <i class="fa-brands fa-twitter"></i>
                  </Link>
                </span>
                <span class="footer_social">
                  <Link to="#">
                    <i class="fa-brands fa-instagram"></i>
                  </Link>
                </span>
              </div>
            </div>
            <div class="col-xl-2 col-md-4 col-sm-6">
              <h6 class="footer_head">Support</h6>
              <ul>
                <li class="footer_link">
                  <Link to="/faqs">FAQ</Link>
                </li>
                <li class="footer_link">
                  <Link to="/help-center">Help Center</Link>
                </li>
                <li class="footer_link">
                  <Link to="/contact">Contact Support</Link>
                </li>
                <li class="footer_link">
                  <Link to="/contact">Accessibility</Link>
                </li>
              </ul>
            </div>
            <div class="col-xl-2 col-md-4 col-sm-6">
              <h6 class="footer_head">LEGAL</h6>
              <ul>
                <li class="footer_link">
                  <Link to="/privacy-policy">Privacy Policy</Link>
                </li>
                <li class="footer_link">
                  <Link to="/terms-and-conditions">Terms of Service</Link>
                </li>
                <li class="footer_link">
                  <Link to="/privacy-policy">Legal Notices</Link>
                </li>
                <li class="footer_link">
                  <Link to="/privacy-policy">
                    Do Not Sell My Personal Information
                  </Link>
                </li>
              </ul>
            </div>
            <div class="col-xl-2 col-md-4 col-sm-6">
              <h6 class="footer_head">ENGAGE WITH US</h6>
              <ul>
                <li class="footer_link">
                  <Link to="/feedback">Feedback</Link>
                </li>
                <li class="footer_link">
                  <Link to="/advertise">Advertise with Us</Link>
                </li>
                <li class="footer_link">
                  <Link to="https://imboxo.io/sponsorship/" target="_blank">
                    {" "}
                    Become a Sponsor
                  </Link>
                </li>
                <li class="footer_link">
                  <Link to="/blogs">blog</Link>
                </li>
              </ul>
            </div>
            <div class="col-xl-2 col-md-4 col-sm-6">
              <h6 class="footer_head">GET INVOLVED</h6>
              <ul>
                <li class="footer_link">
                  <Link to="https://imboxo.io/judges-signup/" target="_blank">
                    Become a Judge
                  </Link>
                </li>
                <li class="footer_link">
                  <Link to="/career">Careers</Link>
                </li>
                <li class="footer_link">
                  <Link to="https://imboxo.io/submit-film/" target="_blank">
                    Submit Your Film
                  </Link>
                </li>
              </ul>
            </div>
            <div class="col-xl-2 col-md-4 col-sm-6">
              <h6 class="footer_head">About IMBOXO</h6>
              <ul>
                <li class="footer_link">
                  <Link to="/about">About Us</Link>
                </li>
                <li class="footer_link">
                  <Link to="/about">Our Mission & Vision</Link>
                </li>
                <li class="footer_link">
                  <Link to="/blogs">Press Releases</Link>
                </li>
                <li>
                  <Link to="/subscription" class="footer_text">
                    Plans
                  </Link>
                </li>
              </ul>
            </div>

            <div class="col-sm-11 mt-3">
              <hr />
              <div class="row align-items-center">
                <div class="col-lg-6 col-md-12">
                  <p class="footer_text">
                    Copyright 2024 © IMBOXO. Powered by
                    <Link to="/">WebArt Technology</Link> All Rights Reserved.
                  </p>
                </div>
                <div class="col-lg-6 col-md-12 d-flex justify-content-end">
                  <small>
                    <Link to="/privacy-policy" class="footer_text">
                      Privacy Policy
                    </Link>
                  </small>
                  <small>
                    <Link to="/terms-and-conditions" class="footer_text">
                      Terms of Service
                    </Link>
                  </small>
                  <small>
                    <Link to="/feedback" class="footer_text">
                      feedback
                    </Link>
                  </small>
                  <small>
                    <Link to="/contact" class="footer_text">
                      Accessibility
                    </Link>
                  </small>

                  <small>
                    <Link
                      to="https://imboxo.io/contact.html"
                      target="_blank"
                      class="footer_text"
                    >
                      Advertise with Us
                    </Link>
                  </small>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
